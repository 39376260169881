import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"


import SEO from "../components/seo"


const NotFound = ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="ページが見つかりません"
      pagedesc="micro:bitを中心にプログラミング教育教材を提供いたします"
      pagepath={location.pathname}
      pageimg={data.about.childImageSharp.original.src}
      pageimgw={data.about.childImageSharp.original.width}
      pageimgh={data.about.childImageSharp.original.height}
    />

    <div className="eyecatch">
      <figure>
        <Img
          fluid={data.about.childImageSharp.fluid}
          alt="notfound"
          loading="eager"
          durationFadeIn={100}
        />
        <h2 style={{ padding: "0 0 10vh", textAlign: "center" }}>
          404 お探しのページが見つかりませんでした。
        </h2>
      </figure>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    about: file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
          height
          width
        }
      }
    }
  }
`

export default NotFound